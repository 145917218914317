import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptorService } from './interceptors/auth/auth-interceptor.service';
import { LoaderModule } from './modules/common/loader/loader.module';
import { StoreModule } from '@ngrx/store';
import { Countries } from './store/interfaces/countries.interface';
import { reducer as countryReducer } from './store/reducers/countries.reducer';
import { reducer as contactReducer } from './store/reducers/contact.reducer';
import { reducer as userProfileReducer } from './store/reducers/userProfile.reducer';
import { DownloadPopupModule } from './modules/common/download-popup/download-popup.module';


// IonicModule
@NgModule({
  declarations: [
    // AuthenticationComponent,
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LoaderModule,
    DownloadPopupModule,
    // ContactsSelectionModule,
    StoreModule.forRoot({
      countries: countryReducer,
      contacts: contactReducer,
      userProfile: userProfileReducer
    }),
    IonicModule.forRoot({})
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
