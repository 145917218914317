import { environment } from 'src/environments/environment';

export const base = environment.base;
export const api = {
  invite: base + '/startup/getPromoText/', //GET
  Login: base + '/user/mobile/login', //POST
  adminLogin: base + '/admin/login', //POST
  validateAdminOtp: base + '/admin/otp', //POST
  googleLogin: base + '/user/social',
  ResendOtp: base + '/user/resendOTP', //POST
  ValidateOtp: base + '/user/mobile/otp', //POST
  ValidateCaretakerOtp: base + '/user/sso/otp', //POST
  RegisterUser: base + '/legacy/register', //POST
  ForgotPassword: base + '/user/forgotPassword', //POST
  CountriesList: base + '/startup/getAllCountries', //GET
  ResetPassword: base + '/user/password/reset', //POST
  UserProfile: base + '/user/details', //GET
  uploadUserPicture: base + '/user/profilePic', //POST
  deleteUserPicture: base + '/user/deleteProfilePic', //DELETE
  ChangePassword: base + '/user/verifyPassword', // POST
  SavePassword: base + '/user/savePassword', //POST
  GetStates: base + '/startup/getAllStates/', //GET
  change2FaSetting: base + '/user/2faSettings', //PUT
  careTaker: base + '/caretaker/', //POST
  updateUserProfile: base + `/user/`, //PUT
  VerifyResetUrl: base + '/user/verifyResetUrl/', //GET
  removeToken: base + '/user/removeToken', //POST
  getKycToken: base + '/user/kyc/token', //GET
  getKycLegacyToken: base + '/user/kyc/legacy/token', //GET
  updateRaKycStatus: base + '/recovery-agent/raKYC', //PUT
  getAlerts: base + '/user/alerts', //GET PUT
  deleteAlert: base + '/user/alerts', // DELET
  updateKYC: base + '/user/kycUpdate', //POST
  updateUserKycStatus: base + '/user/recovery/kycUpdate', //POST
  initiateRecovery: base + '/recovery/initiate',
  updateInitiateRecoverKyc: base + '/user/initiate/kycUpdate', //POST
  getKycDetails: base + '/user/kyc/details', //GET
  guardianOnBoarding: base + '/user/guardianonboarding', //PUT
  userOnBoarding: base + '/user/useronboarding', //PUT
  auditTrail: base + '/reports/myActivity', //POST
  faq: base + '/user/crypto/faq', //GET
  //Will APIs
  saveWill: base + '/cryptowill', //POST
  updateWill: base + '/cryptowill', //PUT
  saveExecutors: base + '/cryptowill/executor/bulk', //POST
  getWillList: base + '/cryptowill', //GET
  finalizeWill: base + '/cryptowill/generateWill', //GET

  //recovery agents
  recoveryAgentCreate: base + '/recovery-agent/create', //POST
  recoveryAgentUpdate: base + '/recovery-agent/update', //PUT
  getRecoveryAgents: base + '/recovery-agent/', //GET
  getMeAsRaList: base + '/user/recovery-agent/', //GET
  finalizeRa: base + '/recovery-agent/finalizeRA', //POST
  recoveryAgentReject: base + '/user/recovery-agent/reject', //POST
  clocrRecoveryAgentReject: base + '/legacy-service/recovery-agent/reject', //PUT
  secreats: base + '/recovery-agent/EnrollSecret', //GET
  resendReq: base + '/recovery-agent/resent', //POST
  resendClocrReq: base + '/legacy-service/recovery-agent/resent', //PUT
  resendClocrRecoveryReq: base + '/legacy-service/recovery/resent', //PUT
  clientAppStatus: base + '/user/clientapp/status', //GET
  deleteRecoveryAgents: base + '/recovery-agent/delete?id=', //DELETE
  keyRecoverRequest: base + '/recovery-agent/SecretKeyRecoverRequest',
  myRequestToRespond: base + '/user/secretKey/request', //POST
  requestInitiatedResent: base + '/user/secretKey/resent', //POST
  rejectSecretRecoverRequest: base + '/user/secretKey/reject', //POST
  rejectSelfSecretRecoverRequest: base + '/legacy-service/recovery/reject', //PUT
  secretKeyDetails:
    base + '/recovery-agent/secretKeyDetails?recovery_agent_id=', //GET
  saveInheritance: base + '/user/inheritance', //POST
  saveInheritanceTrustee: base + '/user/trustee', //POST
  getContracts: base + '/user/inheritance', //GET
  deleteContract: base + '/user/inheritance', // DELETE
  updateContract: base + '/user/inheritance', //PUT
  updateTrusteeEmail: base + '/user/trustee', // PUT DELETE
  getSecretValutDashboardData: base + '/user/secretKey/dashboard', //GET
  thersholdCount: base + '/user/thershold', //GET
  agreeVaultAgreement: base + '/user/agreement', //PUT
  getWillData: base + '/cryptowill', //GET
  deleteExecutor: base + '/cryptowill/executor', //DELETE
  deleteNominee: base + '/cryptowill/nominees', //DELETE
  saveBenificiaries: base + '/cryptowill/nominees/bulk', //POST
  saveWallet: base + '/cryptowill/wallet', //PUT
  createDisbursementRequest: base + '/disbursement/crypto', //POST
  getDisbursementRequests: base + '/disbursement/crypto', //GET
  getDisbursementReasons: base + '/startup/getAllDisbursementReasons', //GET
  getDisbursementUsers: base + '/disbursement/users', //GET
  getDisbursementNotes: base + '/disbursement/crypto/', //GET
  saveDisbursementNotes: base + '/disbursement/crypto/', //POST
  saveDisbursementFiles: base + '/disbursement/crypto/doc/', //POST
  getDisbursementFiles: base + '/disbursement/crypto/doc/', //GET
  getAllDisbursementRequests: base + '/disbursement/crypto/request', //GET
  acceptDisbursementRequest: base + '/disbursement/crypto/accept', //PUT
  rejectDisbursementRequest: base + '/disbursement/crypto/reject', //PUT
  getAdminDisbursementFiles: base + '/admin/crypto/doc/', //GET
  getAdminDisbursementRequests: base + '/admin/crypto/disbursement', //GET
  approveAdminDisbusementRequest: base + '/admin/crypto/disbursement/approve', //PUT
  reviewAdminDisbusementRequest: base + '/admin/crypto/disbursement/review', //PUT
  markAsReadyForDisbursement:
    base + '/admin/crypto/disbursement/ready-for-disbursement', //PUT
  getDisbursementDetails: base + '/admin/crypto/disbursement/request', //PUT
  getDisbursementRecordDetails: base + '/admin/crypto/disbursement/details', //GET
  saveOnboardingInformation: base + '/user/useronboarding', //PUT
  resetRecovery: base + '/recovery-agent/toughbox/resetRecovery', //POST
  getTimeLineData: base + '/disbursement/crypto/audit', //GET
  // updateClientAppStatus: base+'/user/clientapp/status', //PUT
  //contacts
  contacts: base + '/caretaker', //GET DELETE POST
  setRole: base + '/user/role', //PUT
  dashboard: base + '/user/secretKey/dashboard/v1', //GET
  clients: base + '/cryptowill/beneficiary/clients', //GET
  willDetails: base + '/cryptowill/beneficiary/wills', //GET
  allWills: base + '/cryptowill/beneficiary/wills/all', //GET
  trusteeClients: base + '/user/trustee/clients', //GET
  vault: base + '/user/trustee/vaults', //GET
  allVaults: base + '/user/trustee/vaults/all', //GET
  userExists: base + '/user/exists', //POST
  register: base + '/user/registerUser', //POST PUT
  subscriptionList: base + '/crypto/payment/products', //GET
  kycList: base + '/crypto/payment/kyc/products', //GET
  kycDetails: base + '/crypto/payment/kyc/details', //GET
  creditLimit: base + '/crypto/payment/creditLimit', //GET
  stripeSession: base + '/crypto/payment/stripeSession', //POST
  stripeSessionKyc: base + '/crypto/payment/kyc/stripeSession', //POST
  timezone: base + '/user/timezone', //PUT
  cancelSubscription: base + '/crypto/payment/cancelSubscription', //POST
  resumeSubscription: base + '/crypto/payment/resumeSubscription',
  subscriptionHistory: base + '/crypto/payment/subscriptionHistory', //GET
  checkDevicePermission: base + '/recovery-agent/ra_device/verify', //POST
  deleteSecretKey: base + '/user/secret_vault', //DELETE
  trusts: base + '/user/trust', //GET PUT POST DELETE
  getSelfRecoveryKeys: base + '/legacy-service/keys', //GET
  emergencyBinder: base + '/user/emergencyBinder', //GET
  createRA: base + '/legacy-service/recovery-agent/create', //POST
  getRAs: base + '/legacy-service/recovery-agent', //GET
  getRecoveryGuardian: base + '/legacy-service/recovery-agent/request', //GET
  getSelfSecretRequests: base + '/legacy-service/recovery/request', //GET
  cancelRecoveryRequest: base + '/legacy-service/cancelRecovery', //PUT
  updateSecretRecoverStatus:
    base + '/recovery-agent/toughbox/kyc/initateUserRecoveryRequest', //GET
  getRewards: base + '/user/reward-points', //GET
  getWalletIFrameUrl: base + '/user/getfront-api/iframe-url', //GET
  getAccountHoldings: base + '/user/getfront-api/data', //GET
  brokerInfo: base + '/cryptowill/broker', //GET, POST
  updateClientAppStatus: base + '/user/client/update', // GET
  getPromoDetails: base + '/startup/crypto/getPromoText', // GET

  //my legacy routes
  houseHoldDetails: base + '/legacy/houseHold',
  legacyInfo: base + '/legacy/legacyInfo',
  surveyQuestions: base + '/legacy/survey-questions', //GET //POST
  myLegacyActionItems: base + '/legacy/get-my-legacy-actions', //GET
  myLegacyUserActionItems: base + '/legacy/get-user-actions', //GET
  addUserActionItems: base + '/legacy/add-user-action', //POST
  removeUserActionItems: base + '/legacy/remove-user-action', //POST

  personalRecommendations: base + '/legacy/show-personal-recommendations', //GET
  legacyPaymentProducts: base + '/legacy/products/', //GET
  askQuestion: base + '/legacy/chat', //POST
  askQuestionNew: base + '/legacy/chat-v2',
  askQuestionUnregistered: base + '/legacy/chat/unregistered',
  getUserInterest: base + '/legacy/get-user-interest',
  saveUserInterest: base + '/legacy/save-user-interest',
  createStripeSession: base + '/legacy/digital/createStripeSession',
  initiateChat: base + '/legacy/initiate-chat',
  buyChatbotCreditsStripe: base + '/legacy/chatbot/createStripeSession',

  //will
  createWill: base + '/user/will', //POST
  getWill: base + '/user/will', //GET

  // Payment Legacy
  getPaymentProducts: base + '/legacy/products', //GET
  getStripeSession: base + '/legacy/stripeSesssion', //POST

  //creditcardLimit
  getCreditLimit: base + '/legacy/creditLimit', //GET
  createUserReferal: base + '/user/user-refferal', //POST,

  fileCategories: base + '/legacy/file/category', //GET // POST // PUT // DELETE
  fileVaultAddFile: base + '/legacy/file/add', // POST
  fileVaultGetFiles: base + '/legacy/file/list', // POST
  fileVaultFile: base + '/legacy/file',
  fileIce: base + '/legacy/file/ice', // POST

  isEmailReferred: base + '/user/check-referee-email',
  applyPromoCode: base + '/legacy/apply-credits',

  getUserIpDetails: base + '/legacy/user-ip',
  createNewUserSession: base + '/legacy/create-new-session',

  getIceContact : base + '/legacy/contacts',

  // shared with me
  getSharedModules: base + '/legacy/shared-with-modules',
  getModuleData: base + '/legacy/list-module-files',
  shareNewFile: base + '/legacy/save-shared-file',
  getSharedWithMeFiles: base + '/legacy/get-shared-with-me',
  deleteSharedAsset: base + '/legacy/delete-shared-file',
  updateSharedFile: base + '/legacy/update-shared-file',

  //emergencyVault - assetportpolio ice 
  updateAssetIce : base + '/legacy/asset/portfolio/iceVault' , 
  getAssetDetails: base + '/legacy/emergency-vault/assets',
  getSharedcontacts: base + '/legacy/shared-with-me/contacts',
  getSharedMeFile: base + '/legacy/shared-with-me/files',
  //GET DELETE POST

  //update Emergency Conatct
  updateEContact : base + '/caretaker/ecUpdate',

  // Emergency Binder
  getEmergencyBinder: base + '/legacy/getEmergencyBinder', //GET
  createEmergencyBinder: base + '/legacy/createEmergencyBinder', //GET
  setEmergencybinderQuorum: base + '/legacy/setEmergencybinderQuorum', //POST
  openEmergencyBinder: base + '/legacy/openEmergencybinder', //POST
  closeEmergencyBinder: base + '/legacy/closeEmergencybinder', //POST
  getEmergencyBinderAuditTrail: base + '/legacy/getEmergencyBinderAuditTrail', //GET
  getEmergencyBinderOpenRequests: base + '/legacy/getEmergencyBinderOpenRequests', //GET
  approveEmergencyBinderOpenRequest: base + '/legacy/approveEmergencyBinderOpenRequest', //POST
  
};
