import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import(`./modules/root/root.module`).then(
      module => module.RootModule
    ),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import(`./modules/root/root.module`).then(
      module => module.RootModule
    ),
    canActivate: [AuthGuard]
  },
  {
    path: 'onboard',
    loadChildren: () => import('./modules/root/onboarding/onboarding.module').then(
      module => module.OnboardingModule
    )
  },

  {
    path: 'will-preview/:id',
    loadChildren: () => import(`./modules/root/will-preview/will-preview.module`).then(
      module => module.WillPreviewModule
    ),
    canActivate: [AuthGuard]
  },
  {
    path: 'rewards',
    loadChildren: () => import(`./modules/root/rewards/rewards.module`).then(
      module => module.RewardsModule
    ),
  },
  {
    path: '',
    loadChildren: () => import(`./modules/authentication/authentication.module`).then(
      module => module.AuthenticationModule
    )
  },
  {
    path: 'legacybot',
    loadChildren: () => import(`./modules/public-pages/public-pages.module`).then(
      module => module.PublicPagesModule
    )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
